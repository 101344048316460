import { useEffect, useRef } from 'react'


type UseScrollingFrameProps = {
  horizontalOffset: number
  inverted?: boolean
  isVisible: boolean
  verticalOffset: number
}

// TODO: use this hook for landing page
const useScrollingFrame = ({ horizontalOffset, inverted, isVisible, verticalOffset }: UseScrollingFrameProps) => {
  const frameRef = useRef(null)

  useEffect(() => {
    if (!isVisible) {
      return
    }

    let animationFrameId
    let scrollTop
    let blockRect
    const block = frameRef.current

    const frame = () => {
      const blockOffsetTop = blockRect.top + scrollTop
      const scrollWidth = blockRect.width - window.innerWidth + horizontalOffset * 2
      const scrollHeight = (blockOffsetTop < window.innerHeight ? blockOffsetTop : window.innerHeight) - verticalOffset

      animationFrameId = null

      if (blockRect.top <= verticalOffset) {
        block.style.transform = `translateX(${inverted ? 0 : -scrollWidth}px)`

        return
      }

      if (blockRect.top >= scrollHeight) {
        block.style.transform = `translateX(${inverted ? -scrollWidth : 0}px)`

        return
      }

      const percent = scrollTop / scrollHeight
      const value = scrollWidth * percent

      block.style.transform = `translateX(${inverted ? value - scrollWidth : -value}px)`
    }

    const handleScroll = () => {
      scrollTop = window.scrollY
      blockRect = block.getBoundingClientRect()

      if (animationFrameId) {
        return
      }

      animationFrameId = requestAnimationFrame(frame)
    }

    window.addEventListener('scroll', handleScroll, { passive: true })
    handleScroll()

    return () => {
      window.removeEventListener('scroll', handleScroll)
      cancelAnimationFrame(animationFrameId)
    }
  }, [ horizontalOffset, inverted, isVisible, verticalOffset ])

  return frameRef
}

export default useScrollingFrame
